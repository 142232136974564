<template>
  <div class="person-list">
    <div class="person-items" v-for="(t, i) in personList" :key="i">
      <img
        :src="
          t.photo
            ? uploadImgs + t.photo
            : require('../assets/image/task/icon5.png')
        "
      />
      <p>{{ t.name }}</p>
    </div>
    <el-popover
      v-model="isCompleteAssignShow"
      placement="bottom"
      width="400"
      trigger="click"
      popper-class="my-pop-class"
    >
      <div class="popover-box" :style="popoverBoxStyle">
        <div class="popover-title" :style="popoverTitleStyle">
          <div class="popover-left-line" :style="popoverLeftLineStyle"></div>
          请选择负责人
        </div>
        <div class="popover-main" :style="popoverMainStyle">
          <div
            class="popover-item"
            :style="popoverItemStyle"
            v-for="(t, i) in allList"
            :key="t.id"
          >
            <img
              class="item-img"
              :style="popoverItemImgStyle"
              :src="
                t.photo
                  ? uploadImgs + t.photo
                  : require('../assets/image/task/icon5.png')
              "
            />
            <div class="item-other" :style="popoverItemOtherStyle">
              <el-radio-group v-model="radioVal" @change="handleRadioChange(i)">
                <el-radio :label="t.id" v-model="t.id">{{ t.name }}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="popover-footer" :style="popoverFooterStyle">
          <el-button class="ok-button" type="primary" @click="addPopover"
            >确 定</el-button
          >
          <el-button class="no-button" @click="cancelPopover">取 消</el-button>
        </div>
      </div>
      <img
        slot="reference"
        class="add-person-btn"
        src="../assets/image/task/icon4.png"
        @click="addAssign"
      />
    </el-popover>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    addShow: {
      type: Boolean,
    },

    list: {
      type: Array,
    },
    leader_id: {
      type: Number,
    },
  },
  data() {
    return {
      radioVal: "",
      isCompleteAssignShow: false,
      memberTotal: 10,
      memberPer_page: 10,
      memberCurrent_page: 1,
      allList: [],
      personList: [],
      selectLength: [],
      popoverBoxStyle: {
        display: "flex",
        "flex-direction": "column",
      },
      popoverTitleStyle: {
        "font-size": "18px",
        color: "#000",
        "font-weight": "bold",
        display: "flex",
        "margin-bottom": "20px",
        "align-items": "center",
      },
      popoverLeftLineStyle: {
        "background-color": "#000",
        width: "4px",
        height: "21px",
        "border-radius": "3px",
        "margin-right": "10px",
      },
      popoverMainStyle: {
        width: "100%",
        height: "100px",
        display: "flex",
        "flex-wrap": "wrap",
        "overflow-y": "auto",
      },
      popoverItemStyle: {
        display: "flex",
        width: "17.66%",
        "align-items": "center",
        "margin-bottom": "14px",
        "flex-direction": "column",
        "justify-content": "center",
        padding: "0 2px",
      },
      popoverItemImgStyle: {
        width: "42px",
        height: "42px",
        "border-radius": "50%",
        "margin-bottom": "2px",
      },
      popoverItemOtherStyle: {
        width: "100%",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      popoverItemNameStyle: {
        "margin-left": "5px",
      },
      popoverFooterStyle: {
        display: "flex",
        width: "100%",
        "justify-content": "space-around",
      },
    };
  },
  watch: {
    addShow: {
      handler() {
        let obj = this.list.find((item) => item.id == this.leader_id);
        if (obj === undefined) {
          this.personList = [];
          this.radioVal = "";
        } else {
          this.personList.push(obj);
          this.radioVal = obj.id;
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      uploadImgs: (state) => state.app.uploadImgs,
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.allList = JSON.parse(JSON.stringify(this.list));
  },
  destroyed() {
    this.personList = [];
  },
  methods: {
    loadMoreData() {
      if (this.list.length && this.list.length == this.memberTotal) return;
      this.memberPer_page++;
      this.getArticleListApiTime(this.memberPer_page);
    },

    addAssign() {},
    cancelPopover() {
      this.isCompleteAssignShow = false;
    },
    addPopover() {
      this.$emit("addAdminPopover", this.radioVal);
      this.isCompleteAssignShow = false;
    },
    handleRadioChange(index) {
      this.personList[0] = this.list[index];
    },
  },
};
</script>

<style lang="less">
.el-popover.el-popper.my-pop-class {
  padding: 20px !important;
}
.person-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .person-items {
    width: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #749bc8;
    margin-bottom: 14px;
    margin-right: 10px;

    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    p {
      line-height: initial;
      height: auto;
    }
  }
  .add-person-btn {
    cursor: pointer;
    width: 46px;
    height: 46px;
    margin-bottom: 14px;
  }
}
.el-radio.checkbox {
  margin-right: 0;
}
</style>
